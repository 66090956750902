<template>
	<div class="full-height pa-20">
		<h6>{{ program.name }}</h6>
		<div
			class="justify-space-between"
		>

			<div
				class="pa-10 bg-white width-90 mr-30 "
			>
				<h6>문의 답변</h6>
				<table class="table   td-left">
					<col width="120px" />
					<col width="auto" />
					<col width="120px" />
					<col width="auto" />
					<tbody>
						<tr>
							<th>처리 상태</th>
							<td colspan="3">{{ item.state_name }}</td>
						</tr>
						<tr>
							<th>답변자</th>
							<td>{{ user.name }}</td>
							<th>답변일</th>
							<td>{{ item.answerDate ? item.answerDate : date.getToday('-') }}</td>
						</tr>
						<tr>
							<th>답변</th>
							<td colspan="3">
								<editor
									v-if="item.state != '2' && item.qnaIdx"
									height="420px"
									initialEditType="markdown"
									ref="qnaAnswer"
									class="text-left "
									:initialValue="item.answer"
								/>

								<Viewer
									v-if="item.state == '2'"
									:initialValue="item.answer"
									class="pa-10 bg-gray-light box-conents-for-answer"
									style="height: 420px"
								/>
							</td>
						</tr>
					</tbody>
				</table>

				<div
					class="mt-30 justify-center"
				>
					<button
						v-if="item.state != '2'"
						class="bg-identify pa-10-20 mr-10"
						@click="save"
					>저장</button>
					<button
						class="bg-ddd pa-10-20"
						@click="toList"
					>목록</button>
				</div>
			</div>
			<div
				class="pa-10 bg-white width-50 mr-10"
			>
				<h6>문의 내용</h6>
				<table class="table   td-left">
					<col width="120px" />
					<col width="auto" />
					<col width="120px" />
					<col width="auto" />
					<tbody>
						<tr>
							<th>문의 구분</th>
							<td colspan="3">
								{{ item.categoryType }}
							</td>
						</tr>
						<tr>
							<th>가맹점</th>
							<td>{{ item.shopName }}</td>
							<th>작성일</th>
							<td>{{ item.regDate }}</td>
						</tr>
						<tr>
							<th>제목</th>
							<td colspan="3">{{ item.title }}</td>
						</tr>
						<tr>
							<th>내용</th>
							<td colspan="3">
								<Viewer
									v-if="item.content"
									class="pa-10 bg-gray-light box-conents-for-answer"
									:initialValue="item.content"
									style="height: 350px"
								/>
							</td>
						</tr>
						<tr>
							<th>첨부파일</th>
							<td colspan="3">
								<template
									v-for="(file, index) in item.files"
								>
									<button
										:key="'file_' + index"
										class="btn-default pa-5-10 ml-10"
										@click="download(file)"
									><v-icon>mdi mdi-image</v-icon> {{ file.fileName }} <v-icon small>mdi mdi-download</v-icon></button>
								</template>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<Modal
			:is_modal="modal_img"
			:top="true"

			title="첨부파일"

			@close="clear"
			@click="clear"
			@cancel="clear"
		>
			<div slot="modal-content">
				<img :src="file.filePath" class="width-100" />
			</div>
		</Modal>
	</div>
</template>

<script>

import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/vue-editor';

import '@toast-ui/editor/dist/toastui-editor-viewer.css';
import { Viewer } from "@toast-ui/vue-editor";
import Modal from "@/components/Modal";

export default {
	name: 'NoticeDetail'
	,components: {Modal, Editor, Viewer }
	,data: function(){
		return {
			program: {
				name: '1:1문의 상세'
				, top: true
				, title: true
				, bottom: false
			}
			,item: {
			}
			,items_distributor: [
				{ name: '총판을 선택하세요', code: ''}
			]
			,modal_img: false
			,file: {}
		}
	}
	,computed: {

	}
	, methods: {
		save: async function(){
			this.item.qnaAnswer = this.$refs.qnaAnswer.invoke("getMarkdown")
			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'put'
					,url: 'qna/'
					,data: {
						qnaIdx: this.item.qnaIdx
						, qnaAnswer: this.item.qnaAnswer
					}
				})
				if(result.success){
					this.$layout.setNotify( { type: 'success', message: result.message})

				}else{
					this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,getData: async function(){
			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'post'
					,url: 'qna/' + this.$route.params.idx
				})
				if(result.success){
					this.item = result.data
					for(let i = 0; i < this.codes.qna_status.length; i++) {
						if (this.item.state == this.codes.qna_status[i].code) {
							this.item.state_name = this.codes.qna_status[i].name
							break
						}
					}
					this.$set(this.item, 'files', [{
						fileName: this.item.fileName
						,filePath: this.item.filePath
					}])
				}else{
					this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,getDistributor: async function(){
			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'get'
					,url: ''
					,data: this.item
				})
				if(result.success){
					this.items_distributor = result.data
					this.toList()
				}else{
					this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,isCancel: function(){
			this.is_modal = true
		}
		,close: function(){
			this.is_modal = false
			this.modal_img = false
		}
		,clear: function(){
			this.is_modal = false
			this.modal_img = false
		}
		,toList: function(){
			this.$layout.push( { name: 'QnaList', not_query: true})
		}
		,download: function(file){
			this.file = file
			this.modal_img = true
		}
	}
	, created() {
        this.$layout = this.$root.$children[0].$children[0]
        this.user = this.$layout.user
        this.$layout.onLoad(this.program)
		this.getData()
	}
}
</script>

<style>
.toastui-editor-defaultUI-toolbar button {
	margin: 7px 0px;
}
</style>